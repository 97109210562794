import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { connect } from 'react-redux';
import { setCalendars } from '../../store/calendarList';

import './calendarList.scss';

function CalendarList({ dispatch, calendarList, auth: { isSignedIn } }) {
  const [collapsed, setCollapsed] = useState(true);
  useEffect(() => {
    if (gapi && gapi.auth2) {
      gapi?.client?.calendar?.calendarList?.list().then((res) => {
        dispatch(setCalendars(res.result.items));
      });
    }
  }, [isSignedIn]);

  const renderCheckbox = (cal) => (
    <FormControlLabel
      key={cal.id}
      control={
        <Checkbox
          size="small"
          style={{ color: cal.backgroundColor }}
          checked={cal.selected}
        />
      }
      label={<span className="cal-label">{cal.summary}</span>}
    />
  );

  return (
    <div className="calendar-list">
      <div className="calendar-list-header">
        <div>My calendars</div>
        <i className="material-icons" onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? 'keyboard_arrow_down' : 'expand_less'}
        </i>
      </div>
      {!collapsed && (
        <div className="list">
          <div className="calendar-item">
            {calendarList.map(renderCheckbox)}
          </div>
        </div>
      )}
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
  calendarList: state.calendarList,
}))(CalendarList);
