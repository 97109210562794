import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { ROW_HEIGHT } from '../../utils/constants';
import './indicator.scss';

const calculateTop = () => dayjs().diff(dayjs().startOf('d'), 'h', true) * ROW_HEIGHT;

function Indicator() {
  const [top, setTop] = useState(calculateTop());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTop(calculateTop());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div
      id="indicator"
      className="indicator"
      style={{ top }}
    >
      <span className="indicator-label">
        {dayjs().format('HH:mm:ss')}
      </span>
    </div>
  );
}

export default Indicator;
