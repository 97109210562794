import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
  view: 'day',
  start: dayjs().startOf('d').format(),
  end: dayjs().endOf('d').format(),
  showSidebar: true,
};

const calendarViewSlice = createSlice({
  name: 'calendarView',
  initialState,
  reducers: {
    prev: (state) => {
      state.start = dayjs(state.start).subtract(1, state.view).format();
      state.end = dayjs(state.end).subtract(1, state.view).format();
    },
    next: (state) => {
      state.start = dayjs(state.start).add(1, state.view).format();
      state.end = dayjs(state.end).add(1, state.view).format();
    },
    goToToday: (state) => {
      state.start = dayjs().startOf('d').format();
      state.end = dayjs().add(1, state.view).format();
    },
    toggleSidebar: (state) => {
      state.showSidebar = !state.showSidebar;
    },
  },
});

export const {
  prev,
  next,
  goToToday,
  toggleSidebar,
} = calendarViewSlice.actions;
export default calendarViewSlice.reducer;
