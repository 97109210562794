import React from 'react';

const MAX_HOURS = 7;

export const Bar = ({ height = 0, color }) => (
  <div
    className="tally-bar"
    style={{ height: `${height * 2}px`, maxHeight: `${MAX_HOURS * 2 - 1}px`, backgroundColor: color }}
  />
);
