import dayjs from 'dayjs';
import store from '../../../store';
import { ROW_HEIGHT } from '../../../utils/constants';

export const getCursorTime = (payload) => {
  const { cursor: { position }, calendarView } = store.getState();
  const cursorTime = dayjs(calendarView.start).add(position / ROW_HEIGHT, 'h');
  return {
    ...payload,
    cursorTime,
  };
};
