import Input from '@mui/material/Input';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import './tally.scss';
import { TallyRow } from './TallyRow';

const TERMS = 'TERMS';

const summarize = (events, rules) => {
  events.forEach((e) => {
    rules.forEach((r) => {
      r.checkFunction(e, r);
    });
  });
  return rules;
};

const addDurationToDailyAggregate = (duration, day, dailyAggregate) => {
  if (dailyAggregate[day]) {
    dailyAggregate[day] += duration;
  } else {
    dailyAggregate[day] = duration;
  }
};

const allocateEventTimeByDay = (event, dailyAggregate) => {
  const startDatetime = dayjs(event.start.dateTime);
  const endDatetime = dayjs(event.end.dateTime);
  const isSameDay = startDatetime.day() === endDatetime.day();
  if (isSameDay) {
    const eventDuration = endDatetime.diff(startDatetime, 'h', true);
    const day = startDatetime.format('DD');
    addDurationToDailyAggregate(eventDuration, day, dailyAggregate);
  } else {
    const startDay = startDatetime.format('DD');
    const endDay = endDatetime.format('DD');
    const startDayDuration = startDatetime.endOf('d').diff(startDatetime, 'h', true);
    const endDayDuration = endDatetime.diff(endDatetime.startOf('d'), 'h', true);
    addDurationToDailyAggregate(startDayDuration, startDay, dailyAggregate);
    addDurationToDailyAggregate(endDayDuration, endDay, dailyAggregate);
  }
};

const eventStartWith = (prefixArray) => (event, r) => {
  const summaryLowercase = event?.summary?.toLowerCase();
  if (!prefixArray.some((p) => summaryLowercase?.startsWith(p.toLowerCase()))) return;
  allocateEventTimeByDay(event, r.amountPerDay);
};

const eventContains = (patternArray) => (event, r) => {
  const summary = event?.summary?.toLowerCase();
  const patterns = patternArray.map((p) => p.toLowerCase());
  if (!patterns.some((p) => summary?.indexOf(p) > -1)) return;
  allocateEventTimeByDay(event, r.amountPerDay);
};

const eventIsInCalendarFactory = (calendarName) => (event, r) => {
  if (event.organizer.displayName !== calendarName) return;
  allocateEventTimeByDay(event, r.amountPerDay);
};

const getRules = () => {
  const terms = JSON.parse(localStorage.getItem(TERMS) || '[]') || [];
  const customRules = terms
    .map((term) => ({
      label: term,
      checkFunction: eventContains([term]),
      amountPerDay: {},
      color: 'rgb(73, 134, 231)',
    }));
  const defaultRules = [
    {
      label: 'LP',
      checkFunction: eventStartWith(['lp']),
      amountPerDay: {},
      targetAmount: 3,
      color: 'rgb(73, 134, 231)',
    },
    {
      label: 'COE',
      checkFunction: eventStartWith(['coe']),
      amountPerDay: {},
      targetAmount: 3,
      color: 'rgb(73, 134, 231)',
    },
    {
      label: 'C1',
      checkFunction: eventStartWith(['c1']),
      amountPerDay: {},
      targetAmount: 3,
      color: 'rgb(73, 134, 231)',
    },
    {
      label: 'NS1',
      checkFunction: eventStartWith(['ns1']),
      amountPerDay: {},
      targetAmount: 3,
      color: 'rgb(73, 134, 231)',
    },
    {
      label: 'Work',
      checkFunction: eventIsInCalendarFactory('Work'),
      amountPerDay: {},
      color: 'rgb(73, 134, 231)',
    },
    {
      label: 'Life',
      checkFunction: eventIsInCalendarFactory('Life'),
      amountPerDay: {},
      color: 'rgb(123, 209, 72)',
    },
    {
      label: 'Sleep',
      checkFunction: eventIsInCalendarFactory('Sleep'),
      amountPerDay: {},
      color: 'rgb(97, 97, 97)',
    },
  ];
  return [...customRules, ...defaultRules];
};

function Tally({ events, start }) {
  const summary = summarize(events, getRules());
  const currentDay = dayjs(start).format('DD');
  const [term, setTerm] = useState('');
  const [terms, setTerms] = useState(JSON.parse(localStorage.getItem(TERMS) || '[]'));
  const [showInput, setShowInput] = useState(false);

  const add = () => {
    const newTerms = [...terms, term];
    localStorage.setItem(TERMS, JSON.stringify(newTerms));
    setTerms(newTerms);
    setTerm('');
    setShowInput(false);
  };

  const remove = (index) => {
    if (index >= terms.length) return;
    terms.splice(index, 1);
    setTerms([...terms]);
    localStorage.setItem(TERMS, terms);
    setTerm('');
  };

  return (
    <div className="tally">
      <div className="header">
        <div className="title">Tally</div>
        <div className="add" onClick={() => setShowInput(true)}>+</div>
      </div>
      {showInput && (
      <div className="input-section">
        <Input value={term} onChange={(e) => setTerm(e.currentTarget.value)} className="input" size="small" />
        <i className="material-icons" onClick={add}>done</i>
        <i className="material-icons" onClick={() => setShowInput(false)}>close</i>
      </div>
      )}
      {summary.map((e, i) => (
        <TallyRow
          key={e.label}
          label={e.label}
          amount={e.amountPerDay[currentDay]}
          amountPerDay={e.amountPerDay}
          targetAmount={e.targetAmount}
          color={e.color}
          start={start}
          remove={i < terms.length ? () => remove(i) : null}
        />
      ))}
    </div>
  );
}

export default connect(
  (state) => ({
    events: state.events,
    start: state.calendarView.start,
  }),
)(Tally);
