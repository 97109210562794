import React from 'react';

function CalendarRows() {
  return (
    <div>
      {Array.from(Array(24).keys())
        .map((e) => (<div key={e} className="row" />))}
    </div>
  );
}

export default CalendarRows;
