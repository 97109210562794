import { createSlice } from '@reduxjs/toolkit';
import { ROW_HEIGHT } from '../utils/constants';

const initialState = {
  position: 0,
  positionYStepped: null,
  mouseMode: null,
};

export const mouseModes = {
  RESIZE_S: 'RESIZE_S',
  RESIZE_N: 'RESIZE_N',
  MOVE: 'MOVE',
};

const cursorSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setCursor: (state, action) => {
      state.position = action.payload;
    },
    moveUp: (state) => {
      if (state.position > 0) state.position -= ROW_HEIGHT / 4;
    },
    moveDown: (state) => {
      if (state.position < 23.5 * ROW_HEIGHT) state.position += ROW_HEIGHT / 4;
    },
    moveUpFine: (state) => {
      if (state.position > 0) state.position--;
    },
    moveDownFine: (state) => {
      if (state.position < 23.5 * ROW_HEIGHT) state.position++;
    },
    setMouseYStepped: (state, action) => {
      state.positionYStepped = action.payload;
    },
    setMouseMode: (state, action) => {
      state.mouseMode = action.payload;
    },
  },
});

export const {
  setCursor,
  moveUp,
  moveDown,
  moveUpFine,
  moveDownFine,
  setMouseYStepped,
  setMouseMode,
} = cursorSlice.actions;
export default cursorSlice.reducer;
