export const getDurationFromText = (payload) => {
  const durationPattern = /(\d*.?\d+)(hr|min|m|h)/;
  const match = durationPattern.exec(payload.value);

  const newValue = match
    ? payload.value.replace(match[0], '').trim()
    : payload.value;

  const duration = match
    ? {
      amount: match[1],
      unit: match[2][0],
    }
    : null;

  return {
    ...payload,
    value: newValue,
    duration,
  };
};
