import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const calendarListSlice = createSlice({
  name: 'calendarList',
  initialState,
  reducers: {
    setCalendars: (_, action) => action.payload,
  },
});

export const { setCalendars } = calendarListSlice.actions;
export default calendarListSlice.reducer;
