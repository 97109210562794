import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvents: (_, action) => action.payload,
    addEvent: (state, action) => {
      state.push(action.payload);
    },
    deleteEvent: (state, action) => {
      const { event } = action.payload;
      const index = state.findIndex((e) => e.id === event.id);
      state.splice(index, 1);
    },
    updateEvent: (state, action) => {
      const { event } = action.payload;
      const index = state.findIndex((e) => e.id === event.id);
      if (index > -1) state[index] = event;
    },
    selectEvent: (state, action) => {
      const { event } = action.payload;
      const selectedEvent = state.find((e) => e.id === event.id);
      selectedEvent.isSelected = true;
    },
    deselectEvent: (state, action) => {
      const { event } = action.payload;
      const selectedEvent = state.find((e) => e.id === event.id);
      selectedEvent.isSelected = false;
    },
    deselectEvents: (state) => {
      state.forEach((e) => {
        e.isSelected = undefined;
      });
    },
  },
});

export const {
  setEvents, addEvent, deleteEvent, updateEvent, selectEvent, deselectEvent, deselectEvents,
} = eventsSlice.actions;
export default eventsSlice.reducer;
