import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { ROW_HEIGHT } from '../../utils/constants';
import './cursor.scss';
import {
  moveUp, moveUpFine, moveDown, moveDownFine,
} from '../../store/cursor';

function Indicator({
  dispatch,
  cursor: {
    position,
  },
}) {
  const checkKey = (event) => {
    switch (event.key) {
      case 'ArrowUp':
        event.preventDefault();
        if (event.altKey) {
          dispatch(moveUpFine());
        } else {
          dispatch(moveUp());
        }
        break;
      case 'ArrowDown':
        event.preventDefault();
        if (event.altKey) {
          dispatch(moveDownFine());
        } else {
          dispatch(moveDown());
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', checkKey, true);
    return () => {
      document.removeEventListener('keydown', checkKey, true);
    };
  });
  const getCursorTime = () => dayjs()
    .startOf('day')
    .add(position / ROW_HEIGHT, 'h')
    .format('HH:mm');

  const color = 'rgb(73, 134, 231)';
  const top = position;
  const label = getCursorTime();

  return (
    <div
      className="cursor"
      style={{
        top,
        backgroundColor: color,
      }}
    >
      <span
        className="cursor-label"
        style={{ color }}
      >
        {label}
      </span>
    </div>
  );
}

export default connect(
  (state) => ({
    cursor: state.cursor,
  }),
)(Indicator);
