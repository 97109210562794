import React from 'react';
import { createRoot } from 'react-dom/client';
// import './fontello-993a36ac/css/calendar.css';
import './index.css';
import { Provider } from 'react-redux';
import App from './components/App';
import registerKeyboard from './utils/registerKeyboard';
import authenticate from './utils/authenticate';
import store from './store';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

window.addEventListener('touchstart', (e) => {
  if (e.targetTouches.length === 2) {
    e.preventDefault();
  }
}, false);

registerKeyboard();
authenticate();
