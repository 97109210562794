import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSignedIn: false,
  profileUrl: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signedIn: (state, action) => {
      state.isSignedIn = true;
      state.profileUrl = action.payload.profileUrl;
    },
    signedOut: () => initialState,
  },
});

export const { signedIn, signedOut } = authSlice.actions;
export default authSlice.reducer;
