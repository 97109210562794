import React, { useEffect } from 'react';
import './app.scss';
import MainCalendar from '../MainCalendar';
import NavBar from '../NavBar';
import Sidebar from '../Sidebar';

function App() {
  useEffect(() => {
    const indicator = document.getElementById('indicator');
    if (indicator) indicator.scrollIntoView();
  });

  return (
    <div className="App">
      <NavBar />
      <div className="body">
        <Sidebar />
        <MainCalendar />
      </div>
    </div>
  );
}

export default App;
