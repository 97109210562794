import React from 'react';
import { TallyLast7Days } from './TallyLast7Days';

// PX_PER_HOUR is the number of pixels per hour
// MAX_HOURS is the maximum number of hours to display
const PX_PER_HOUR = 25;
const MAX_HOURS = 4;
export const TallyRow = ({
  label,
  amount = 0,
  amountPerDay,
  targetAmount,
  color,
  start,
  remove,
}) => (
  <div className="tally-row">
    <div className="tally-daily">
      <div className="label">
        {label}
        {remove && (
          <i className="material-icons" onClick={remove}>
            close
          </i>
        )}
      </div>
      <div className="amount">{amount.toFixed(2)}</div>
      <div
        className="progress"
        style={{
          backgroundColor: color,
          width: Math.min(amount * PX_PER_HOUR, MAX_HOURS * PX_PER_HOUR),
        }}
      />
      {targetAmount && (
        <div
          className="target"
          style={{
            borderLeftColor: color,
            left:
              (Math.min(targetAmount * PX_PER_HOUR, MAX_HOURS * PX_PER_HOUR) *
                new Date().getHours()) /
              24,
          }}
        />
      )}
    </div>
    <TallyLast7Days amounts={amountPerDay} color={color} start={start} />
  </div>
);
