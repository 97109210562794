import { getCalendar } from './getCalendar';
import { getDurationFromText } from './getDurationFromText';
import { getCursorTime } from './getCursorTime';

export const processValue = (payload) => {
  const analyzedPayload = [
    getCalendar,
    getDurationFromText,
    getCursorTime,
  ].reduce((acc, t) => {
    const res = t(acc);
    return res;
  }, payload);

  const processedPayload = (payload.processors || [])
    .reduce((acc, t) => {
      const res = t(acc);
      return res;
    }, analyzedPayload);

  return processedPayload;
};
