import dayjs from 'dayjs';
import store from '../store';

const defaultOptions = {
  calendarId: 'primary',
  timeMin: store.getState().calendarView.start,
  timeMax: store.getState().calendarView.end,
  showDeleted: false,
  singleEvents: true,
  orderBy: 'startTime',
};

function eventsListSingleCalendar(options) {
  if (!gapi || !gapi.client) return Promise.resolve([]);
  return gapi.client.calendar.events
    .list({
      ...defaultOptions,
      ...options,
    })
    .then((res) => res.result.items);
}

function eventsListMultiCalendar(options) {
  const arrayOfRequests = options.calendarIds.map((id) =>
    eventsListSingleCalendar({
      ...options,
      calendarId: id,
    })
  );
  return Promise.all(arrayOfRequests).then((res) =>
    res.reduce((acc, eventsList) => {
      acc.push(...eventsList);
      return acc;
    }, [])
  );
}

export const EventsAPI = {
  list: (options) =>
    Array.isArray(options.calendarIds)
      ? eventsListMultiCalendar(options)
      : eventsListSingleCalendar(options),
  patch: (updateBody) => gapi.client.calendar.events.patch(updateBody),
  move: (updateBody) => gapi.client.calendar.events.move(updateBody),
  insert: (options) => gapi.client.calendar.events.insert(options),
  delete: (options) => gapi.client.calendar.events.delete(options),
};

/**
 * Lists events from the last 7 days for selected calendars, ending on specified day
 * Includes proper error handling and type checking for Google API
 *
 * @param {Date|string} day - The end date to calculate the 7-day window from
 * @returns {Promise<Array>} Array of calendar events
 */
export function listEventsLast7DaysForSelectedCalendarsEndingOn(day) {
  // Check if Google API is properly loaded and initialized
  if (!window.gapi) {
    console.error('Google API client not loaded');
    return Promise.reject(new Error('Google API client not loaded'));
  }

  if (!gapi.client) {
    console.error('Google API client not initialized');
    return Promise.reject(new Error('Google API client not initialized'));
  }

  if (!gapi.client.calendar) {
    console.error('Google Calendar API not initialized');
    return Promise.reject(new Error('Google Calendar API not initialized'));
  }

  // Wrap the API call in a try-catch to handle potential runtime errors
  try {
    return gapi.client.calendar.calendarList
      .list()
      .then((res) => {
        if (!res.result || !res.result.items) {
          throw new Error('Invalid response from calendar list');
        }

        const calendarIds = res.result.items
          .filter((cal) => cal.selected)
          .map((cal) => cal.id);

        if (calendarIds.length === 0) {
          console.warn('No calendars selected');
          return [];
        }

        return EventsAPI.list({
          calendarIds,
          timeMin: dayjs(day).subtract(7, 'd').startOf('d').format(),
          timeMax: dayjs(day).add(7, 'd').endOf('d').format(),
        });
      })
      .catch((error) => {
        console.error('Error fetching calendar events:', error);
        throw error;
      });
  } catch (error) {
    console.error('Error in calendar API call:', error);
    return Promise.reject(error);
  }
}

// Helper function to check if Google API is ready
export function isGoogleApiReady() {
  return !!(window.gapi && gapi.client && gapi.client.calendar);
}

// Example usage with proper initialization check
export async function safelyLoadCalendarEvents(day) {
  if (!isGoogleApiReady()) {
    console.error('Google Calendar API is not ready');
    // Optionally trigger re-initialization of Google API here
    return [];
  }

  try {
    const events = await listEventsLast7DaysForSelectedCalendarsEndingOn(day);
    return events;
  } catch (error) {
    console.error('Failed to load calendar events:', error);
    return [];
  }
}
