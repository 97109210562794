import React, { useEffect } from 'react';
import './calendarHeader.scss';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { next, prev } from '../../store/calendarView';

function CalendarHeader({ dispatch, toolbar, calendarView: { start } }) {
  const checkKey = (event) => {
    const isToolbarOpen = toolbar.show;
    switch (event.key) {
      case 'j':
        if (!isToolbarOpen) {
          event.preventDefault();
          dispatch(next());
        }
        break;
      case 'k':
        if (!isToolbarOpen) {
          event.preventDefault();
          dispatch(prev());
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', checkKey, true);
    return () => {
      document.removeEventListener('keydown', checkKey, true);
    };
  });

  const isToday = dayjs(start).isSame(dayjs(), 'day');

  return (
    <div className="cal-header">
      <div className="label">
        <span className="label-text">TO</span>
      </div>
      <div className="heading">
        <span className="border-left" />
        <div className={classNames('weekday', { isToday })}>
          {dayjs(start).format('ddd')}
        </div>
        <div className={classNames('day-numeric', { isToday })}>
          {dayjs(start).format('D')}
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    calendarView: state.calendarView,
    toolbar: state.toolbar,
  }),
)(CalendarHeader);
