import store from '../store';
import {
  hideToolbar,
  toggleToolbar,
  toolbarNewEvent,
} from '../store/toolbar';

export default function registerKeyboard() {
  const checkKey = (event) => {
    const isToolbarOpen = store.getState().toolbar.show;
    switch (event.key) {
      case '/':
        event.preventDefault();
        store.dispatch(toggleToolbar());
        break;
      case 'n':
        if (!isToolbarOpen) {
          event.preventDefault();
          store.dispatch(toolbarNewEvent());
        }
        break;
      case 'Escape':
        event.preventDefault();
        store.dispatch(hideToolbar());
        break;
      default:
        break;
    }
  };
  document.addEventListener('keydown', checkKey, true);
}
