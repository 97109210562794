import React from 'react';
import dayjs from 'dayjs';
import { Bar } from './Bar';

const get7DayRange = (start) => {
  const dayRange = [];
  for (let i = dayjs(start).subtract(7, 'd'); dayjs(start).diff(i, 'd') >= 0; i = i.add(1, 'd')) {
    dayRange.push(i.format('DD'));
  }
  return dayRange;
};

export const TallyLast7Days = ({ amounts = {}, color, start }) => (
  <div className="tally-weekly">
    {get7DayRange(start).map((day) => <Bar key={day} height={amounts[day]} color={color} />)}
  </div>
);
