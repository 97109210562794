import dayjs from 'dayjs';
import { createSelector } from 'reselect';

const getCalendarView = (state) => state.calendarView;
const selectEvents = (state) => state.events;

export const selectEventsBetweenStartAndEnd = createSelector(
  [getCalendarView, selectEvents],
  (calendarView, events) => {
    const { start, end } = calendarView;
    const eventsBetweenStartAndEnd = events.filter((e) => {
      if (e.start.date || e.end.date) return false;
      if (dayjs(start).diff(dayjs(e.end.dateTime)) >= 0) return false;
      if (dayjs(end).diff(dayjs(e.start.dateTime)) < 0) return false;
      return true;
    });
    return eventsBetweenStartAndEnd;
  },
);
