import React from 'react';
import './mainCalendar.scss';
import { connect } from 'react-redux';
import classNames from 'classnames';
import CalendarHeader from '../CalendarHeader';
import Events from '../Events';
import Toolbar from '../Toolbar';
import Indicator from '../Indicator';
import Cursor from '../Cursor';
import {
  setCursor, setMouseYStepped, setMouseMode, mouseModes,
} from '../../store/cursor';
import {
  getYPositionRounded,
  getTimeFromPositionYStepped,
  updateEventDatetimeEnd,
  updateEventDatetimeStart,
} from '../../utils/eventHelpers';
import { deselectEvents, updateEvent } from '../../store/events';
import { EventsAPI } from '../../api/events';
import CalendarRows from './CalendarRows';

const getTimeLabel = (hour) => `${((hour) % 12) || 12} ${hour < 12 ? 'AM' : 'PM'}`;

function MainCalendar({
  dispatch, mouseMode, calendarEvent,
}) {
  const handleClick = (event) => {
    if (mouseMode) return;
    const roundedPosition = getYPositionRounded(event);
    dispatch(setCursor(roundedPosition));
  };

  const handleDragOver = (e) => {
    const roundedPosition = getYPositionRounded(e);
    dispatch(setMouseYStepped(roundedPosition));
  };

  const mouseMove = (e) => {
    if (!mouseMode) return;
    const roundedPosition = getYPositionRounded(e);
    dispatch(setMouseYStepped(roundedPosition));
  };

  const reset = () => {
    dispatch(setMouseMode(null));
    dispatch(setMouseYStepped(null));
    dispatch(deselectEvents());
  };

  const mouseUp = () => {
    if (!mouseMode) return;
    let eventWithUpdatedDatetime;
    if (mouseMode === mouseModes.RESIZE_S) {
      const newEndDatetime = getTimeFromPositionYStepped();
      if (!newEndDatetime) {
        reset();
        return;
      }
      eventWithUpdatedDatetime = updateEventDatetimeEnd(calendarEvent, newEndDatetime);
      dispatch(updateEvent({ event: eventWithUpdatedDatetime }));
    } else if (mouseMode === mouseModes.RESIZE_N) {
      const newStartDatetime = getTimeFromPositionYStepped();
      eventWithUpdatedDatetime = updateEventDatetimeStart(calendarEvent, newStartDatetime);
      dispatch(updateEvent({ event: eventWithUpdatedDatetime }));
    }

    const updateBody = {
      calendarId: calendarEvent.organizer.email,
      eventId: calendarEvent.id,
      ...eventWithUpdatedDatetime,
    };

    EventsAPI.patch(updateBody)
      .then((res) => {
        const updatedEvent = res.result;
        dispatch(updateEvent({ event: updatedEvent }));
      });

    reset();
  };

  const isResizing = mouseMode === mouseModes.RESIZE_N
    || mouseMode === mouseModes.RESIZE_S;

  return (
    <div className="main-calendar">
      <Toolbar />
      <CalendarHeader />
      <div className="viewport">
        <div className="time-label">
          <div className="label" />
          {Array.from(Array(23).keys()).map((e) => (
            <div key={e} className="label">
              {getTimeLabel(e + 1)}
            </div>
          ))}
        </div>
        <div className="cal-column">
          <CalendarRows />
          <div className="cal-border" />
          <div
            id="cal-body"
            className={classNames('cal-body', { 'is-resizing': isResizing })}
            onDragOver={handleDragOver}
            onMouseMove={mouseMove}
            onMouseUp={mouseUp}
            onClick={handleClick}
          >
            <Indicator />
            <Cursor />
            <Events />
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    auth: state.auth,
    calendarList: state.calendarList,
    calendarEvent: state.events.find((e) => e.isSelected),
    mouseMode: state.cursor.mouseMode,
  }),
)(MainCalendar);
