import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import './event.scss';
import classNames from 'classnames';
import {
  getTimeFromPosition, updateEventDatetime,
} from '../../utils/eventHelpers';
import { deselectEvent, selectEvent, updateEvent } from '../../store/events';
import { setMouseYStepped, setMouseMode, mouseModes } from '../../store/cursor';
import { EventsAPI } from '../../api/events';

dayjs.extend(isBetween);

function Event({
  dispatch, event, positionYStepped, mouseMode, style
}) {
  const [showResizeIndicator, setShowResizeIndicator] = useState(false);
  const handleDragStart = (dragEvent) => {
    if (mouseMode === mouseModes.RESIZE_N || mouseMode === mouseModes.RESIZE_S) {
      dragEvent.preventDefault();
      dragEvent.stopPropagation();
      return;
    }
    dispatch(selectEvent({ event }));
    const img = document.getElementById('drag');
    dragEvent.dataTransfer.setDragImage(img, 0, 0);
  };

  const handleDragEnd = () => {
    const newStartDatetime = getTimeFromPosition(positionYStepped);
    const eventWithUpdatedDatetime = updateEventDatetime(event, newStartDatetime);
    dispatch(updateEvent({ event: eventWithUpdatedDatetime }));

    const updateBody = {
      calendarId: event.organizer.email,
      eventId: event.id,
      ...eventWithUpdatedDatetime,
    };

    EventsAPI.patch(updateBody)
      .then((res) => {
        const updatedEvent = res.result;
        dispatch(updateEvent({ event: updatedEvent }));
      });

    dispatch(setMouseYStepped(null));
    dispatch(deselectEvent({ event }));
  };

  const resizeStartN = () => {
    dispatch(selectEvent({ event }));
    dispatch(setMouseMode(mouseModes.RESIZE_N));
  };

  const resizeStartS = () => {
    dispatch(selectEvent({ event }));
    dispatch(setMouseMode(mouseModes.RESIZE_S));
  };

  const showResize = showResizeIndicator || (
    (mouseMode === mouseModes.RESIZE_N || mouseMode === mouseModes.RESIZE_S) && event.isSelected);

  const isResizing = mouseMode === mouseModes.RESIZE_N || mouseMode === mouseModes.RESIZE_S;

  return (
    <div
      key={event.id}
      className={classNames('event', { 'is-resizing': isResizing })}
      style={style}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onMouseEnter={() => setShowResizeIndicator(true)}
      onMouseLeave={() => setShowResizeIndicator(false)}
      draggable
    >
      <span className="event-text-darken">
        {event.summary}
      </span>
      <div className="resize n" onMouseDown={resizeStartN}>
        {showResize && <i className="resize-indicator" />}
      </div>
      <div className="resize s" onMouseDown={resizeStartS}>
        {showResize && <i className="resize-indicator" />}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    calendarList: state.calendarList,
    calendarView: state.calendarView,
    positionYStepped: state.cursor.positionYStepped,
    mouseMode: state.cursor.mouseMode,
  }),
)(Event);
