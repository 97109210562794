import React from 'react';
import { connect } from 'react-redux';
import './navBar.scss';

function AuthButton({ isSignedIn, profileUrl }) {
  const signIn = () => gapi.auth2.getAuthInstance().signIn();
  const signOut = () => gapi.auth2.getAuthInstance().signOut();
  if (isSignedIn) {
    return (
      <div className="auth">
        <button
          type="button"
          className="button-auth"
          onClick={signOut}
        >
          <img
            className="profile-circle"
            src={profileUrl}
            alt="sign out button"
          />
          Sign Out
        </button>
      </div>
    );
  }

  return (
    <div className="auth">
      <button
        type="button"
        className="button-auth"
        onClick={signIn}
      >
        Sign In
      </button>
    </div>
  );
}

export default connect(
  (state) => ({
    count: state.auth.count,
    isSignedIn: state.auth.isSignedIn,
    profileUrl: state.auth.profileUrl,
  }),
)(AuthButton);
