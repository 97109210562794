import React from 'react';
import { connect } from 'react-redux';
import { toggleSidebar } from '../../store/calendarView';
import AuthButton from './AuthButton';
import DateControl from './DateControl';
import './navBar.scss';

function NavBar({ dispatch }) {
  return (
    <div className="navbar">
      <div className="nav-left">
        <i
          className="material-icons nav-menu"
          onClick={() => dispatch(toggleSidebar())}
        >
          menu
        </i>
        <p className="title">Calendar</p>
        <DateControl />
      </div>
      <AuthButton />
    </div>
  );
}

export default connect()(NavBar);
