import { combineReducers } from '@reduxjs/toolkit';
import auth from './auth';
import calendarList from './calendarList';
import events from './events';
import cursor from './cursor';
import toolbar from './toolbar';
import calendarView from './calendarView';

const rootReducer = combineReducers({
  auth,
  calendarList,
  events,
  cursor,
  toolbar,
  calendarView,
});

export default rootReducer;
