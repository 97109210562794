import dayjs from 'dayjs';

export const format = (event) => {
  const { summary, organizer: { displayName } } = event;

  const start = dayjs(event.start.dateTime);
  const end = dayjs(event.end.dateTime);

  const durationInHours = end.diff(start, 'h', true);
  const durationInMinutes = end.diff(start, 'm', true);
  const duration = durationInHours < 1 ? `${durationInMinutes}m` : `${durationInHours}h`;

  const getCalendar = () => {
    switch (displayName) {
      case 'Work':
        return '#w';
      case 'Waste':
        return '#wa';
      case 'Life':
        return '#l';
      case 'Sleep':
        return '#s';
      default:
        return '';
    }
  };

  const valueComponents = [
    getCalendar(),
    `${duration}`,
    summary,
  ];

  return valueComponents.join(' ');
};
