import dayjs from 'dayjs';
import store from '../store';
import { mouseModes } from '../store/cursor';
import { ROW_HEIGHT } from './constants';

export const groupOverlappingEvents = (events) => {
  const sortedEvents = [...events].sort((a, b) =>
    dayjs(a.start.dateTime).diff(dayjs(b.start.dateTime))
  );

  const groups = [];
  let currentGroup = [];

  sortedEvents.forEach((event) => {
    if (
      currentGroup.length === 0 ||
      dayjs(event.start.dateTime).isBefore(
        dayjs(currentGroup[currentGroup.length - 1].end.dateTime)
      )
    ) {
      currentGroup.push(event);
    } else {
      groups.push(currentGroup);
      currentGroup = [event];
    }
  });

  if (currentGroup.length > 0) {
    groups.push(currentGroup);
  }

  return groups;
};

export const getTimeFromPosition = (position) => {
  const { calendarView } = store.getState();
  const datetime = dayjs(calendarView.start).add(position / ROW_HEIGHT, 'h');
  return datetime;
};

export const getTimeFromPositionYStepped = () => {
  const {
    calendarView,
    cursor: { positionYStepped },
  } = store.getState();
  if (positionYStepped === null) return null;
  const datetime = dayjs(calendarView.start).add(
    positionYStepped / ROW_HEIGHT,
    'h'
  );
  return datetime;
};

export const getDurationFromEvent = (calendarEvent, unit = 'm') => {
  const startDatetime = dayjs(calendarEvent.start.dateTime);
  const endDatetime = dayjs(calendarEvent.end.dateTime);
  return endDatetime.diff(startDatetime, unit, true);
};

export const updateEventDatetimeEnd = (calendarEvent, newEndDatetime) => {
  const updatedEvent = {
    ...calendarEvent,
    end: {
      dateTime: newEndDatetime.format(),
      timeZone: 'America/Toronto',
    },
  };
  return updatedEvent;
};

export const updateEventDatetimeStart = (calendarEvent, newStartDatetime) => {
  const updatedEvent = {
    ...calendarEvent,
    start: {
      dateTime: newStartDatetime.format(),
      timeZone: 'America/Toronto',
    },
  };
  return updatedEvent;
};

export const updateEventDatetime = (calendarEvent, newStartDatetime) => {
  const duration = getDurationFromEvent(calendarEvent, 'm');
  const newEndDatetime = newStartDatetime.add(duration, 'm');
  const updatedEvent = {
    ...calendarEvent,
    start: {
      dateTime: newStartDatetime.format(),
      timeZone: 'America/Toronto',
    },
    end: {
      dateTime: newEndDatetime.format(),
      timeZone: 'America/Toronto',
    },
  };
  return updatedEvent;
};

export const getClosestThresholdPosition = (yPosition) => {
  const threshold = 0.25 * ROW_HEIGHT;
  return Math.round(yPosition / threshold) * threshold;
};

const getYPositionFromMouseEvent = (event) => {
  const { clientY: clickY } = event;
  const calBody = document.getElementById('cal-body');
  const calBodyY = calBody.getBoundingClientRect().y;
  const clickYDiffCalBodyY = clickY - calBodyY;
  return clickYDiffCalBodyY;
};

export const getYPositionRounded = (event) => {
  const yPosition = getYPositionFromMouseEvent(event);
  const roundedPosition = getClosestThresholdPosition(yPosition);
  return roundedPosition;
};

export const getEventAtPosition = () => {
  const {
    calendarView,
    events,
    cursor: { position },
  } = store.getState();
  const cursorTime = dayjs(calendarView.start).add(position / ROW_HEIGHT, 'h');
  const index = events.findIndex((e) => {
    const startTime = dayjs(e.start.dateTime);
    const endTime = dayjs(e.end.dateTime);
    const res = cursorTime.isBetween(startTime, endTime, null, '[)');
    return res;
  });
  if (index === -1) return null;
  const event = events[index];
  return event;
};

export const getCalendarColor = (calendarId) => {
  const { calendarList } = store.getState();
  const calendar = calendarList.find((cal) => cal.id.indexOf(calendarId) > -1);
  return calendar ? calendar.backgroundColor : '#fbdf93';
};

export const calculateEventWidth = (_, overlapGroup, totalGroups) => {
  const left = `${(overlapGroup / totalGroups) * 100}%`;
  const width = `${100 / totalGroups}%`;
  return { left, width };
};

export const calculateEventHeight = (event) => {
  const {
    calendarView,
    cursor: { positionYStepped, mouseMode },
  } = store.getState();
  const start = dayjs(event.start.dateTime);
  const end =
    dayjs(event.end.dateTime) > dayjs(calendarView.end)
      ? dayjs(calendarView.end)
      : dayjs(event.end.dateTime);

  let top = start.diff(dayjs(calendarView.start), 'h', true) * ROW_HEIGHT;
  if (event.isSelected && positionYStepped !== null && !mouseMode) {
    top = positionYStepped;
  }

  const minHeight = ROW_HEIGHT * 0.25;
  let height = end.diff(start, 'h', true) * ROW_HEIGHT - 1;
  if (
    event.isSelected &&
    positionYStepped !== null &&
    mouseMode === mouseModes.RESIZE_S
  ) {
    height =
      positionYStepped - top < minHeight ? minHeight : positionYStepped - top;
  }
  if (
    event.isSelected &&
    positionYStepped !== null &&
    mouseMode === mouseModes.RESIZE_N
  ) {
    height = height + top - positionYStepped;
    top = positionYStepped;
  }

  return { top, height };
};

export const calculateEventStyle = (event, overlapGroup, totalGroups) => {
  if (!event.start) return null;
  if (event.start.date && !event.start.datetime) return null;

  const { top, height } = calculateEventHeight(event);
  const { left, width } = calculateEventWidth(event, overlapGroup, totalGroups);
  const calendarId = event.organizer.email;
  const color = getCalendarColor(calendarId);
  const backgroundColor = `${color}60`;
  const borderLeft = `2px solid ${color}`;

  return {
    top,
    height,
    left,
    width,
    color,
    backgroundColor,
    borderLeft,
  };
};

export const prepareEventStyles = (events) => {
  const groups = groupOverlappingEvents(events);
  const styles = [];

  groups.forEach((group) => {
    group.forEach((event, index) => {
      styles.push({
        event,
        style: calculateEventStyle(event, index, group.length),
      });
    });
  });

  return styles;
};
