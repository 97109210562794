import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { goToToday, next, prev } from '../../store/calendarView';

function DateControl({ dispatch, start }) {
  const currentDay = dayjs(start).format('MMM YYYY');
  return (
    <div className="date-control">
      <button
        onClick={() => dispatch(goToToday())}
        type="button"
        className="today"
      >
        Today
      </button>
      <button
        onClick={() => dispatch(prev())}
        type="button"
        className="previous"
      >
        <i className="material-icons">
          keyboard_arrow_left
        </i>
      </button>
      <button
        onClick={() => dispatch(next())}
        type="button"
        className="next"
      >
        <i className="material-icons">
          keyboard_arrow_right
        </i>
      </button>
      <div className="current-day">
        {currentDay}
      </div>
    </div>
  );
}

export default connect((state) => ({
  start: state.calendarView.start,
}))(DateControl);
