import dayjs from 'dayjs';
import { EventsAPI } from '../../../api/events';
import store from '../../../store';
import { updateEvent } from '../../../store/events';

export const editEvent = (payload) => {
  const { duration, eventOnFocus } = payload;

  const startDatetime = dayjs(eventOnFocus.start.dateTime);
  const endDatetime = duration
    ? startDatetime.add(duration.amount, duration.unit)
    : startDatetime.add(15, 'm');

  const event = {
    summary: payload.value,
    start: {
      dateTime: startDatetime.format(),
      timeZone: 'America/Toronto',
    },
    end: {
      dateTime: endDatetime.format(),
      timeZone: 'America/Toronto',
    },
  };

  const updateBody = {
    calendarId: eventOnFocus.organizer.email,
    eventId: eventOnFocus.id,
    ...event,
  };

  const requireMoveToDifferentCalendar = payload.calendarId !== eventOnFocus.organizer.email;

  EventsAPI.patch(updateBody)
    .then((res) => {
      const updatedEvent = res.result;
      if (!requireMoveToDifferentCalendar) store.dispatch(updateEvent({ event: updatedEvent }));

      const moveBody = {
        calendarId: updatedEvent.organizer.email,
        eventId: updatedEvent.id,
        destination: payload.calendarId,
      };
      EventsAPI.move(moveBody)
        .then((moveRes) => {
          store.dispatch(updateEvent({ event: moveRes.result }));
        });
    });

  return {
    ...payload,
  };
};
