import { EventsAPI } from '../../../api/events';
import store from '../../../store';
import { addEvent } from '../../../store/events';

export const createEvent = (payload) => {
  const { duration, cursorTime } = payload;

  const startDatetime = cursorTime;
  const endDatetime = duration
    ? startDatetime.add(duration.amount, duration.unit)
    : startDatetime.add(15, 'm');

  const event = {
    summary: payload.value,
    start: {
      dateTime: startDatetime.format(),
      timeZone: 'America/Toronto',
    },
    end: {
      dateTime: endDatetime.format(),
      timeZone: 'America/Toronto',
    },
  };

  EventsAPI.insert({
    calendarId: payload.calendarId || 'primary',
    resource: event,
  }).then((res) => {
    store.dispatch(addEvent(res.result));
  });

  return {
    ...payload,
  };
};
