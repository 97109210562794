import { createSlice } from '@reduxjs/toolkit';
import { format } from '../utils/formatters';

const initialState = {
  value: '',
  show: false,
  mode: 'utility',
  eventOnFocus: null,
};

const toolbarSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    hideToolbar: (state) => {
      state.show = false;
    },
    toggleToolbar: (state) => {
      state.show = !state.show;
    },
    toolbarUtility: (state) => ({
      ...state,
      show: !state.show,
      mode: 'utility',
    }),
    toolbarNewEvent: (state) => {
      switch (state.mode) {
        case 'edit':
          return {
            ...state,
            show: true,
            mode: 'new',
            value: '',
          };
        default:
          return {
            ...state,
            show: true,
            mode: 'new',
          };
      }
    },
    toolbarEditEvent: (state, action) => ({
      ...state,
      show: true,
      mode: 'edit',
      value: format(action.payload),
      eventOnFocus: action.payload,
    }),
    updateToolbarValue: (state, action) => {
      state.value = action.payload;
    },
    resetToolbar: () => initialState,
  },
});

export const {
  hideToolbar,
  resetToolbar,
  toolbarUtility,
  toggleToolbar,
  toolbarNewEvent,
  toolbarEditEvent,
  updateToolbarValue,
} = toolbarSlice.actions;
export default toolbarSlice.reducer;
