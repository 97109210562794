import store from '../../../store';

export const getCalendar = (payload) => {
  const { value } = payload;
  const { calendarList } = store.getState();
  const t = value?.toLowerCase();
  let calendarName;
  switch (true) {
    case t.indexOf('#wa') > -1:
    case t.indexOf('#waste') > -1:
      calendarName = 'Waste';
      break;
    case t.indexOf('#w') > -1:
    case t.indexOf('#wo') > -1:
    case t.indexOf('#work') > -1:
      calendarName = 'Work';
      break;
    case t.indexOf('#l') > -1:
    case t.indexOf('#life') > -1:
      calendarName = 'Life';
      break;
    case t.indexOf('#s') > -1:
    case t.indexOf('#sleep') > -1:
      calendarName = 'Sleep';
      break;
    default:
      calendarName = 'dl.uoft@gmail.com';
      break;
  }

  const matchedCalendar = calendarList.find((c) => c.summary === calendarName);
  const calendarId = matchedCalendar ? matchedCalendar.id : 'primary';

  const newValue = ['#waste', '#wa', '#work', '#wo', '#w', '#life', '#l', '#sleep', '#s']
    .reduce((a, p) => a.replace(p, ''), payload.value)
    .trim();

  return {
    ...payload,
    calendarId,
    value: newValue,
  };
};
