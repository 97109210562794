import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import './events.scss';
import { setEvents } from '../../store/events';
import Event from '../Event';
import { safelyLoadCalendarEvents } from '../../api/events';
import { selectEventsBetweenStartAndEnd } from './getEvents';
import { prepareEventStyles } from '../../utils/eventHelpers';

dayjs.extend(isBetween);

function Events(props) {
  const { dispatch, events, calendarList, calendarView } = props;

  useEffect(() => {
    safelyLoadCalendarEvents(calendarView.start)
      .then((res) => dispatch(setEvents(res)))
      .catch(console.error);
  }, [calendarList, calendarView.start]);

  const eventStyles = prepareEventStyles(events);

  return eventStyles.map(({ event, style }) => (
    <Event key={event.id} style={style} event={event} />
  ));
}

export default connect((state) => ({
  events: selectEventsBetweenStartAndEnd(state),
  calendarList: state.calendarList,
  calendarView: state.calendarView,
}))(Events);
