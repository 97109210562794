import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { processValue } from './processors';
import './toolbar.scss';
import {
  resetToolbar,
  toolbarEditEvent,
  updateToolbarValue,
} from '../../store/toolbar';
import { createEvent } from './processors/createEvent';
import { editEvent } from './processors/editEvent';
import { getEventAtPosition } from '../../utils/eventHelpers';
import { deleteEvent } from '../../store/events';
import { EventsAPI } from '../../api/events';

const getAssets = (mode) => {
  switch (mode) {
    case 'utility':
      return {
        placeholder: 'Please enter command then tab ⇥',
        preIcon: 'keyboard_arrow_right',
        postIcon: 'keyboard_return',
      };
    case 'new':
      return {
        placeholder: 'Enter new event details',
        preIcon: 'add',
        postIcon: 'keyboard_return',
      };
    case 'edit':
      return {
        placeholder: 'Edit event details',
        preIcon: 'edit',
        postIcon: 'keyboard_return',
      };
    default:
      return {
        placeholder: 'Please enter command then tab ⇥',
        preIcon: 'keyboard_arrow_right',
        postIcon: 'keyboard_return',
      };
  }
};

function Toolbar(props) {
  const { toolbar, dispatch } = props;
  const deleteEventAtCursor = () => {
    const event = getEventAtPosition();
    if (!event) return;
    const { organizer: { email }, id } = event;

    EventsAPI.delete({
      calendarId: email,
      eventId: id,
    }).then(() => dispatch(deleteEvent({ event })));
  };

  const editEventAtCursor = () => {
    const event = getEventAtPosition();
    dispatch(toolbarEditEvent(event));
  };

  const checkKey = (event) => {
    const isToolbarOpen = toolbar.show;
    switch (event.key) {
      case 'd':
        if (!isToolbarOpen) {
          event.preventDefault();
          deleteEventAtCursor();
        }
        break;
      case 'e':
        if (!isToolbarOpen) {
          event.preventDefault();
          editEventAtCursor();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', checkKey, true);
    return () => {
      document.removeEventListener('keydown', checkKey, true);
    };
  });

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      switch (toolbar.mode) {
        case 'new':
          processValue({
            value: toolbar.value,
            processors: [createEvent],
          });
          break;
        case 'edit':
          processValue({
            value: toolbar.value,
            eventOnFocus: toolbar.eventOnFocus,
            processors: [editEvent],
          });
          break;
        default:
          break;
      }
      dispatch(resetToolbar());
    }
  };

  const handleOnChange = (e) => {
    dispatch(updateToolbarValue(e.target.value));
  };

  const {
    placeholder,
    preIcon,
    postIcon,
  } = getAssets(toolbar.mode);

  return (
    toolbar.show && (
      <div className="utility-toolbar shadow">
        <i className="material-icons">{preIcon}</i>
        <input
          autoFocus
          placeholder={placeholder}
          value={toolbar.value}
          onKeyPress={handleKeyPress}
          onChange={handleOnChange}
          className="utility-input"
          type="text"
        />
        <i className="material-icons">{postIcon}</i>
      </div>
    )
  );
}

export default connect(
  (state) => ({
    toolbar: state.toolbar,
  }),
)(Toolbar);
