import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import CalendarList from '../CalendarList';
import Tally from '../Tally';

import './sidebar.scss';

function Sidebar({ showSidebar }) {
  return (
    <div className={classNames('sidebar', { closed: !showSidebar })}>
      <Tally />
      <CalendarList />
    </div>
  );
}

export default connect(
  (state) => ({
    showSidebar: state.calendarView.showSidebar,
  }),
)(Sidebar);
